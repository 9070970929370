.fade-in {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  .activeIcon path {
    stroke: #2943D6; 
  }
  .inActiveIcon path{
    stroke: #030229CC;
  }
 .customeForm :where(.css-dev-only-do-not-override-12lgwbt).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px !important;
  }
  @media (min-width: 576px) {
      .customInput  :where(.css-dev-only-do-not-override-84nlvj).ant-col-sm-16 {
          max-width: 100% !important;
        }}
        .customInput element.style {
          width: 100% !important;
        }
