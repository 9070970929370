.delete-modal .ant-modal-content {
  background-color: #ffffff;
}

.delete-modal .ant-modal-footer {
  border-top: none;
}

.ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  align-items: center;
  /* justify-items: center; */
  border: 0;
  /* width: 400px; */
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 20px 24px;
}

.ant-modal .ant-modal-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  background-color: none;
}

/* span {
  font-size: 1rem;
  margin-top: -3px;
} */
