:where(.css-dev-only-do-not-override-12lgwbt).ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-start-start-radius: 0;
  border-start-end-radius: 6px;
  border-end-end-radius: 6px;
  border-end-start-radius: 0;
  background-color: #4629eb;
}
