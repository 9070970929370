.rdrInputRange{
    display: none;
    font-size: x-small;
}
:where(.css-dev-only-do-not-override-84nlvj).ant-input-group .ant-input-group-addon, :where(.css-dev-only-do-not-override-84nlvj).ant-input-group .ant-input-group-wrap{
    display: none;
}
:where(.css-dev-only-do-not-override-84nlvj).ant-select-single:not( .ant-select-customize-input ) .ant-select-selector {

    height: 30px !important;
}
:where(.css-dev-only-do-not-override-84nlvj).ant-input-group .ant-input{
    background-color: transparent;
}
 @media (min-width: 576px) {
    .customForm  :where(.css-dev-only-do-not-override-12lgwbt).ant-col-sm-8{
min-width: 100% !important;
        }}