/* custom-checkbox.css ===============start */
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  @apply bg-green-500 border-green-500;
}

.custom-checkbox .ant-checkbox-checked::after {
  @apply border-green-500;
}

.custom-checkbox .ant-checkbox-inner {
  @apply h-5 w-5;
}

.custom-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  @apply ring-2 ring-green-500;
}
/* custom-checkbox.css ==============end*/
