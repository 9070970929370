.custom-table td,
.custom-table th {
  padding: 2px !important;
  margin: 0 !important;
  /* width: 20px !important; Adjust the width as needed */
  font-size: 14px;
}
.recharts-legend-wrapper {
    width: fit-content !important;
}
/* .recharts-responsive-container{
    width: fit-content !important;
} */

.ant-table-cell{
    /* width: 20px !important; */
}