.custom-option {
  height: 40px;
}
@media only screen and (min-width: 1024px) and (max-width: 1536px) {
  .custom-option {
    height: 20px;
  }
}
:where(.css-dev-only-do-not-override-84nlvj).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  height: 50px;
  width: "320px";
  border: none;
  /* background-color: #eff0f6; */
}
@media only screen and (min-width: 1024px) and (max-width: 1536px) {
  :where(.css-dev-only-do-not-override-84nlvj).ant-select-single:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    height: 35px;
  }
}
:where(
    .css-dev-only-do-not-override-84nlvj
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
:where(
    .css-dev-only-do-not-override-84nlvj
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 260px;
  background-color: rgba(239, 240, 246, 0.5);
}
:where(
    .css-dev-only-do-not-override-84nlvj
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container,
:where(
    .css-dev-only-do-not-override-84nlvj
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container,
:where(
    .css-dev-only-do-not-override-84nlvj
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-container,
:where(
    .css-dev-only-do-not-override-84nlvj
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-container {
  width: 260px;
}
@media only screen and (min-width: 1024px) and (max-width: 1536px) {
  :where(
      .css-dev-only-do-not-override-84nlvj
    ).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select,
  :where(
      .css-dev-only-do-not-override-84nlvj
    ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload.ant-upload-select {
    width: 200px;
    background-color: rgba(239, 240, 246, 0.5);
  }
  :where(
      .css-dev-only-do-not-override-84nlvj
    ).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-container,
  :where(
      .css-dev-only-do-not-override-84nlvj
    ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-container,
  :where(
      .css-dev-only-do-not-override-84nlvj
    ).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-circle
    .ant-upload-list-item-container,
  :where(
      .css-dev-only-do-not-override-84nlvj
    ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-circle
    .ant-upload-list-item-container {
    width: 200px;
  }
}

.wrapper {
  position: relative;
  width: 400px;
  height: 200px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-signature-canvas {
  stroke-width: 0px;
}
.signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  width: 400px;
  height: 200px;
}
:where(.css-dev-only-do-not-override-84nlvj).ant-col-sm-8 {
  width: 100%;
}
:where(.css-dev-only-do-not-override-1me4733).ant-modal-confirm
  .ant-modal-confirm-btns
  .ant-btn
  + .ant-btn {
  background-color: orange;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 5px;
}
:where(.css-dev-only-do-not-override-1me4733).ant-btn-default {
  background-color: rgb(6, 6, 190);
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 5px;
}
.css-o4yt5i-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: rgb(6, 6, 146);
}
button#\:re\::hover {
  background-color: rgba(128, 128, 128, 0.667);
  color: black;
}
button#\:rf\::hover {
  background-color: rgb(6, 6, 146);
  color: white;
}
@media print {
  .printBtn {
    display: none;
  }
  .drawText {
    display: none;
  }
}
