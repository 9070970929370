.hide-scrollbar {
    overflow-y: auto; 
    overflow-x: hidden; 
    
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    width: 2px; 
  }
  
  .hide-scrollbar::-webkit-scrollbar-thumb {
    /* background-color: #f4f6fa9f !important;  */
    border-radius: 10px;
    opacity: 0; 
    transition: opacity 0.3s, background-color 0.3s; 
  }
  
  .hide-scrollbar:hover::-webkit-scrollbar-thumb {
    opacity: 1 !important; 
  }
  
  .hide-scrollbar {
    -ms-overflow-style: none; 
  }
  
  .hide-scrollbar:hover {
    -ms-overflow-style: scrollbar; 
  }
  .hide-scrollbar {
    scrollbar-width: none; 
  }
  
  .hide-scrollbar:hover {
    scrollbar-width: auto; 
  }
  