:where(.css-dev-only-do-not-override-84nlvj) .custom-modal .ant-modal-content {
  position: relative;
  background-color: transparent;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: none;
  pointer-events: auto;
  padding: 20px 24px;
}
